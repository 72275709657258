import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { OtherRoutes } from "./OtherRoutes";
import { RoutePath } from "./RoutePath";
import { AdminRoutes } from "./AdminRoutes";
import { HomeRoute } from "./HomeRoute";
import { UserRoute } from "./UserRoute";
import { NftRoute } from "./NftRoute";
import { TransactionRoute } from "./TransactionRoute";
import { ScrollToTop } from "../common/ScrollToTop";

export const Routing = () => {

  const globalContext = useContext(GlobalContext);

  let paths = RoutePath
  // concat other routes if the role is admin
  if (globalContext.userInfo.role === "ADMIN") {
    paths = paths.concat(AdminRoutes)
  } else {
    if (globalContext.userInfo.dashboard_read === 1) paths = paths.concat(HomeRoute)
    if (globalContext.userInfo.user_mgt_read === 1) paths = paths.concat(UserRoute)
    if (globalContext.userInfo.nft_mgt_read === 1) paths = paths.concat(NftRoute)
    if (globalContext.userInfo.transaction_mgt_read === 1) paths = paths.concat(TransactionRoute)
  }

  return (
    <ScrollToTop>
      <Routes>
        {/* Show Login component on every path if not logged in */}
        {!globalContext.loggedIn &&
          OtherRoutes.map((d, i) =>
            <Route key={i} path={d.path} element={d.element} />
          )}
        {globalContext.loggedIn &&
          // Routing for all pages after logging in
          paths.map((d, i) =>
            <Route key={i} path={d.path} element={d.element} />
          )}
      </Routes>
    </ScrollToTop>
  );
};
