import axios from "axios";
import { Headers } from "../configuration/ConfigHeaders";
import { BASE_URL } from "../configuration/Config";

// Header with user token
function configHeaders() {
    return {
        headers: Headers()
    }
}

// Login api for admin
export const LoginApi = (params) => {
    let url = `${BASE_URL}/admin-login`
    return axios.post(url, params)
}

// Login if 2fa is enabled
export const ValidateCode = (params) => {
    let url = `${BASE_URL}/2fa/validate/code`
    return axios.post(url, params)
}

// Get my profile detail api
export const GetProfile = () => {
    let url = `${BASE_URL}/my-profile`
    return axios.get(url, configHeaders())
}

// Dashboard data api
export const DashboardApi = (startDate, endDate) => {
    let url = `${BASE_URL}/dash-board?start_date=${startDate}&end_date=${endDate}`
    return axios.get(url, configHeaders())
}

// Get recent 5 transaction api
export const TransactionActivity = () => {
    let url = `${BASE_URL}/admin/transaction-activity`
    return axios.get(url, configHeaders())
}

// User listing api
export const UserListApi = (search, page, type) => {
    let url = `${BASE_URL}/admin/user-list?keyword=${search}&page=${page}&type=${type}`
    return axios.get(url, configHeaders())
}

// Api for enable/disable user status
export const UpdateUserStatus = (params, id) => {
    let url = `${BASE_URL}/admin/user-status/${id}`
    return axios.put(url, params, configHeaders())
}

// Get particular user's transactions api
export const UserTransactionsApi = (id, page) => {
    let url = `${BASE_URL}/admin/user-Transaction/${id}?page=${page}`
    return axios.get(url, configHeaders())
}

// Get NFT transactions api
export const NftListApi = (search, page) => {
    let url = `${BASE_URL}/admin/nfts-list?page=${page}&keyword=${search}`
    return axios.get(url, configHeaders())
}

// Api for enable/disable NFTs
export const UpdateNftStatus = (params, id) => {
    let url = `${BASE_URL}/admin/nft-status/${id}`
    return axios.put(url, params, configHeaders())
}

// Get particular nft transactions api
export const NftTransactionApi = (id, page) => {
    let url = `${BASE_URL}/admin/nftTransaction/${id}?page=${page}`
    return axios.get(url, configHeaders())
}

// Get all transactions api
export const TransactionsApi = (page, startDate, endDate) => {
    let url = `${BASE_URL}/admin/transaction-list?page=${page}&start_date=${startDate}&end_date=${endDate}`
    return axios.get(url, configHeaders())
}

// Create sub-admin api
export const CreateSubAdmin = (params) => {
    let url = `${BASE_URL}/admin/signup-subadmin`
    return axios.post(url, params, configHeaders())
}

// Get sub-admin list api
export const SubAdminListingApi = (page, search) => {
    let url = `${BASE_URL}/admin/subAdmin-list?page=${page}&keyword=${search}`
    return axios.get(url, configHeaders())
}

// Update sub-admin profile api
export const UpdateSubAdmin = (params, id) => {
    let url = `${BASE_URL}/admin/update-profile/${id}`
    return axios.put(url, params, configHeaders())
}

// Delete sub-admin api
export const DeleteSubAdmin = (id) => {
    let url = `${BASE_URL}/admin/delete/${id}`
    return axios.patch(url, {}, configHeaders())
}

// SubAdmin logs api
export const SubAdminLogs = (id, page, startDate, endDate, search) => {
    let url = `${BASE_URL}/admin/user/logs/${id}?page=${page}&keyword=${search}&startDate=${startDate}&endDate=${endDate}`
    return axios.get(url, configHeaders())
}

// Admin profile update api
export const UpdateAdmin = (params) => {
    let url = `${BASE_URL}/admin/profile/update`
    return axios.put(url, params, configHeaders())
}

// Export data to csv api
export const ExportToCsv = (path, ids) => {
    let url = `${BASE_URL}/admin/export/csv/${path}?${ids}`
    // Open link in new tab to download the csv
    window.open(url, "_blank")
}

// Get QR code api
export const GetQR = () => {
    let url = `${BASE_URL}/admin/2fa/detail`
    return axios.get(url, configHeaders())
}

// Verify security code while enable 2fa api
export const EnableFa = (params) => {
    let url = `${BASE_URL}/admin/2fa/enable`
    return axios.post(url, params, configHeaders())
}

// Disable 2fa api
export const DisableFa = (params) => {
    let url = `${BASE_URL}/admin/2fa/disable`
    return axios.post(url, params, configHeaders())
}

// Send forgot password token api
export const ForgotToken = (parmas) => {
    let url = `${BASE_URL}/forgot-password`
    return axios.post(url, parmas)
}

// Reset password api
export const ResetPassword = (params) => {
    let url = `${BASE_URL}/reset-password`
    return axios.post(url, params)
}
