// Copy text to clipboard
export const ShowCopyText = (data) => {
    navigator.clipboard.writeText(data);
    var tooltip = document.getElementById("copy-text");
    tooltip.innerHTML = "Copied";
}

// Remove Copied text
export const RemoveCopyText = () => {
    var tooltip = document.getElementById("copy-text");
    tooltip.innerHTML = "";
}