import React from "react"
import { ImageShimmer } from "../../common/ImageShimmer"
import { ReactTooltip } from "../../common/ReactTooltip"
import { SubText } from "../../common/SubText"
import { WeiToEth } from "../../common/WeiToEth"
import { useNavigate } from "react-router-dom"

export const NftData = (props) => {
    function handleContextMenu(event) {
        event.preventDefault();
    }

    const navigate = useNavigate();
    console.log(props);
    return (
        <div className="user-detail">
            <h4 className="mb-2"> Detail:</h4>
            <div className="detail-area detail-area-two">
                <div className="user-profile user-profile-detail">
                    <td className="nft-pitcures">
                            {props.nft_media_type === null || props.nft_media_type === "image" ? (
                              <ImageShimmer source={props.nft_image} width={55} height={55} />
                            ) : (
                                <video width={55} height={55} controls={false}>
                                <source
                                    src={props.nft_image}
                                    type="video/mp4"
                                    onContextMenu={handleContextMenu}
                                    controlsList="nodownload"
                                    preload='none'
                                    // controls={false}
                                    playsinline
                                />
                            </video>
                            )}
                          </td>
                    <div className="details-name">
                        <h6>{props.nft_name}</h6>
                        <p>
                            {props.nft_description}
                        </p>
                    </div>
                </div>
                <div className="user-info">
                    <p>
                        Category: <span>{props.nft_category}</span>
                    </p>
                    <p>
                        Current Price: <span>{WeiToEth(props.nft_price)}</span>
                    </p>
                    <p>
                        Collection: <span>{props.collection_name ? props.collection_name : "NA"}</span>
                    </p>
                    {props.nft_type === "MULTIPLE" &&
                        <p>
                            Available Quantity: <span>{props.nft_avail_for_sale}</span>
                        </p>}
                </div>
                <div className="user-info">
                    {props.nft_type === "MULTIPLE" &&
                        <p>
                            Total Quantity: <span>{props.total_qty}</span>
                        </p>}
                    <ReactTooltip text={props.nft_creator}>
                        <p>
                            Creator: <span role="button" onClick={() => navigate(`/user_management?page=1&search=${props.nft_creator}`)}> {props.nft_creator ? SubText(props.nft_creator) : ""}</span>
                        </p>
                    </ReactTooltip>
                    <p>
                        Royalty: <span>{props.nft_royality}</span>
                    </p>

                    <p>
                        NFT Type: <span>{props.nft_type}</span>
                    </p>
                </div>
            </div>
        </div>
    )

}