import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { DataPagination } from "../../common/DataPagination";
import { ShowFormatedDate } from "../../common/FormatDate";
import { ReactTooltip } from "../../common/ReactTooltip";
import { SubText } from "../../common/SubText";
import { WeiToEth } from "../../common/WeiToEth";
import {
    NftTransactionApi,
    TransactionActivity,
    UserTransactionsApi,
} from "../../services/Services";
import { useNavigate } from "react-router-dom";
import { ADMIN_WALLET } from "../../configuration/Config";

export const CommonTransactions = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [msg, setMsg] = useState("Loading...");
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        setMsg("Loading...");
        // Get particular user transactions
        if (props.apiType === "userTransactions") getUserTransactions();
        // Get recent 5 transactions
        if (props.apiType === "recentTransactions") getRecentTrans();
        // Get particular nft transactions
        if (props.apiType === "nftTransactions") getNftTransaction();
    }, [props, page]);

    const getUserTransactions = () => {
        if (props.id) {
            UserTransactionsApi(props.id, page - 1)
                .then((res) => {
                    setMsg("No Data Found");
                    setData(res.data.data);
                    setTotalItems(res.data.total_records);
                })
                .catch((err) => {
                    console.log("user transactions error ", err);
                });
        } else {
            setMsg("No Data Found");
            setData([]);
            setTotalItems(0);
        }
    };

    const getRecentTrans = () => {
        TransactionActivity()
            .then((res) => {
                setMsg("No Data Found");
                setData(res.data.data);
                props.setCount(res.data.data.length)
            })
            .catch((err) => {
                console.log("recent transaction error ", err);
            });
    };

    const getNftTransaction = () => {
        if (props.id) {
            NftTransactionApi(props.id, page - 1)
                .then((res) => {
                    setMsg("No Data Found");
                    setData(res.data.data);
                    setTotalItems(res.data.total_records);
                })
                .catch((err) => {
                    console.log("nft transactions error ", err);
                });
        } else {
            setMsg("No Data Found");
            setData([]);
            setTotalItems(0);
        }
    };

    return (
        <div className="dashboad-table user-management-right-area mt-0">
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>NFT</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length && (props.id || props.apiType === "recentTransactions") ? (
                        data.map((d, i) => (
                            <tr key={i}>
                                <td>
                                    <img
                                        src={require("../../assets/images/minted.png")}
                                        alt="sale"
                                    />{" "}
                                    {d.log_type.replace(/_/g, ' ')}
                                </td>
                                <td>
                                    <div>{d.nft_name ?<span role="button" onClick={() => navigate(`/nft_management?page=1&search=${d.nft_name}`)}>{d.nft_name}</span> : "N/A"}</div>
                                </td>
                                <td>{d.quantity ? d.quantity : "N/A"}</td>
                                <td>{WeiToEth(d.price)}</td>
                                <td>
                                    {/* Tooltip component using react bootstrap */}
                                    {d.seller ?
                                        <ReactTooltip text={d.seller}>
                                            <div role="button" onClick={() => navigate(`/user_management?page=1&search=${d.seller}`)}>
                                                {SubText(d.seller)}
                                            </div>
                                        </ReactTooltip>
                                        : "N/A"}
                                </td>
                                <td>
                                    {/* Tooltip component using react bootstrap */}
                                    <ReactTooltip text={d.user}>
                                        <div role="button" onClick={() => {
                                            if(d.user !== ADMIN_WALLET) navigate(`/user_management?page=1&search=${d.user}`)
                                        }}>
                                            {d.user ? d.user === ADMIN_WALLET ? "Admin's wallet" : SubText(d.user) : ""}
                                        </div>
                                    </ReactTooltip>
                                </td>
                                <td>
                                    {ShowFormatedDate(d.created_at)}{" "}
                                    {/* <img
                                        src={require("../../assets/images/share.png")}
                                        alt="sale"
                                    /> */}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="not-data-found">
                                {msg}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {/* Pagination component */}
            {totalItems > 10 && (
                <DataPagination
                    page={page}
                    totalItems={totalItems}
                    changePage={setPage}
                />
            )}
        </div>
    );
};
