import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { SidebarOption } from "./SidebarOption";

const Sidebar = ({ isOpen, setIsOpen }) => {

  const globalContext = useContext(GlobalContext);

  // Handle show hide for the sidebar
  const ToggleSidebar = () => {
    isOpen === true ? setIsOpen(false) : setIsOpen(true);
  };

  return (
    <>
      <button className="barbtn" onClick={ToggleSidebar}>
        <img src={require("../assets/images/menu.png")} alt="menu"></img>
      </button>
      <div className="sidebar-overlay">
        <section className="sidebar">
          <div className="logo">
            <img alt="" src={require("../assets/images/logo.png")} />
          </div>
          {/* Sidebar component for side bar menu and functionality */}
          {globalContext.userInfo.dashboard_read === 1 && <SidebarOption name="Dashboard" path="home" tabName="home" img={require("../assets/images/sidebaricon-1.png")} />}
          {globalContext.userInfo.user_mgt_read === 1 && <SidebarOption name="User Management" path="user_management?page=1" tabName="user_management" img={require("../assets/images/sidebaricon-2.png")} />}
          {globalContext.userInfo.nft_mgt_read === 1 && <SidebarOption name="NFT Management" path="nft_management?page=1" tabName="nft_management" img={require("../assets/images/sidebaricon-3.png")} />}
          {globalContext.userInfo.transaction_mgt_read === 1 && <SidebarOption name="Transaction Management" path="transaction_management?page=1" tabName="transaction_management" img={require("../assets/images/sidebaricon-4.png")} />}
          {globalContext.userInfo.role === "ADMIN" && <SidebarOption name="Sub Admin" path="sub_admin?page=1" tabName="sub_admin" img={require("../assets/images/sidebaricon-5.png")} />}
          <SidebarOption name="Sign Out" path="sign_out" tabName="sign_out" img={require("../assets/images/sidebaricon-6.png")} />
        </section>
      </div>
    </>
  );
};
export default Sidebar;
