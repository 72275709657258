import React, { useContext, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ImageShimmer } from "../../common/ImageShimmer";
import { GlobalContext } from "../../context/GlobalContext";
import { UpdateProfile } from "./UpdateProfile";

export const Profile = () => {

  const globalContext = useContext(GlobalContext);
  const [show, setShow] = useState(false);

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={6}>
          <div className="profile-area">
            <div className="profile-main-image">
              {/* Shimmer component for effect till image doesn't get loaded */}
              <ImageShimmer source={globalContext.userInfo.profile} width={250} height={250} />
            </div>
            <div className="profile-area-edit-btn">
              <Button type="button" variant="unset" onClick={() => setShow(true)}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
                Edit Profile
              </Button>
            </div>
            <div className="profile-content">
              {globalContext.userInfo.firstname && <h6>
                Name: <span>{`${globalContext.userInfo.firstname} ${globalContext.userInfo.lastname}`}</span>
              </h6>}
              <h6>
                Username: <span>{globalContext.userInfo.username}</span>
              </h6>
              <h6>
                Email: <span>{globalContext.userInfo.email}</span>
              </h6>
              {globalContext.userInfo.ethAddress && <h6>
                Wallet Address: <span>{globalContext.userInfo.ethAddress}</span>
              </h6>}
            </div>
          </div>
        </Col>
      </Row>
      {/* update admin profile component */}
      <UpdateProfile show={show} setShow={setShow} />
    </Container>
  );
};
