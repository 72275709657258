import React, { useContext, useEffect } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { UpdateSubAdmin } from "../../services/Services";

export const AdminSettings = (props) => {

    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm();
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        setValue("username", props.username)
        setValue("email", props.email)
        setValue("dashboard_read", props.dashboard_read)
        setValue("user_mgt_read", props.user_mgt_read)
        setValue("user_mgt_write", props.user_mgt_write)
        setValue("nft_mgt_read", props.nft_mgt_read)
        setValue("nft_mgt_write", props.nft_mgt_write)
        setValue("transaction_mgt_read", props.transaction_mgt_read)
    }, [props])

    const updateSubAdmin = (data) => {
        globalContext.setLoader(true)
        const formData = new FormData()
        formData.append("username", data.username)
        formData.append("email", data.email)
        formData.append("dashboard_read", data.dashboard_read ? 1 : 0)
        formData.append("user_mgt_read", data.user_mgt_read ? 1 : 0)
        formData.append("user_mgt_write", data.user_mgt_write ? 1 : 0)
        formData.append("nft_mgt_read", data.nft_mgt_read ? 1 : 0)
        formData.append("nft_mgt_write", data.nft_mgt_write ? 1 : 0)
        formData.append("transaction_mgt_read", data.transaction_mgt_read ? 1 : 0)
        if (data.password) formData.append("password", data.password)
        if (data.profile.length) formData.append("profile", data.profile[0])
        UpdateSubAdmin(formData, props.id).then(res => {
            globalContext.setLoader(false)
            Swal.fire("Sub-admin updated successfully").then(() => {
                reset({
                    password: "",
                    profile: ""
                })
                props.setUpdatePage(updatePage => updatePage + 1)
            }
            )
        }).catch(err => {
            globalContext.setLoader(false)
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
            Swal.fire(err.response.data.message, '', 'error')
        })
    }

    return (
        <div className="sub-admin-content-area">
            <Form onSubmit={handleSubmit(updateSubAdmin)}>
                <div className="user-update-box">
                    <h5>User settings</h5>
                    <Button type="submit" className="update-btn-box" variant="unset"><i className="fa fa-floppy-o" aria-hidden="true"></i></Button>
                </div>
                <Row className="p-3">
                    <Col md={6}>
                        <div className="create-users-form-area">
                            <Form.Group className="mb-2">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" {...register("username", { required: "Name is required" })} />
                                {errors.username && <span className="err-msg">{errors.username.message}</span>}
                            </Form.Group>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="create-users-form-area">
                            <Form.Group className="mb-2" >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    {...register("email", { required: "Email is required" })}
                                />
                                {errors.email && <span className="err-msg">{errors.email.message}</span>}
                            </Form.Group>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="create-users-form-area">
                            <Form.Group className="mb-2">
                                <Form.Label> Image</Form.Label>
                                <div style={{ position: "relative" }}>
                                    <Form.Control
                                        {...register("profile", {
                                            validate: (values, formValues) => values[0] ?  values[0].size < 4000000 || "Max image size is 4MB" : true,
                                        }
                                        )}
                                        type="file"
                                        className="file-input"
                                        accept="image/*"
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Choose Image"
                                        className="Choose Image"
                                        value={watch("profile") !== undefined ? watch("profile").length > 0 ? watch("profile")[0].name : "" : ""}
                                        disabled={true}
                                    />
                                    <i
                                        className="fa fa-plus add-image-icon"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                                {errors.profile && <span className="err-msg">{errors.profile.message}</span>}
                            </Form.Group>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="create-users-form-area">
                            <Form.Group className="mb-2">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter password" {...register("password", {
                                    minLength: {
                                        value: 8,
                                        message: "Password should have atleast 8 characters"
                                    }
                                })} />
                                {errors.password && <span className="err-msg">{errors.password.message}</span>}
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
            </Form>
            <Table bordered>
                <thead>
                    <tr className="user-setting-area">
                        <th>Permission</th>
                        <th>Read</th>
                        <th>Write</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Dashboard Interface</td>
                        <td>
                            <div className="user-management-check">
                                <Form.Check aria-label="option 1" {...register("dashboard_read")} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>User Management</td>
                        <td>
                            <div className="user-management-check">
                                <Form.Check aria-label="option 1" {...register("user_mgt_read", {
                                    onChange: (e) => {
                                        if (!(e.target.checked)) setValue("user_mgt_write", false)
                                    }
                                })} />
                            </div>
                        </td>
                        <td>
                            <div className="user-management-check">
                                <Form.Check aria-label="option 1" {...register("user_mgt_write", {
                                    onChange: (e) => {
                                        if (e.target.checked) setValue("user_mgt_read", true)
                                    }
                                })} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>NFT Management</td>
                        <td>
                            <div className="user-management-check">
                                <Form.Check aria-label="option 1" {...register("nft_mgt_read", {
                                    onChange: (e) => {
                                        if (!(e.target.checked)) setValue("nft_mgt_write", false)
                                    }
                                })} />
                            </div>
                        </td>
                        <td>
                            <div className="user-management-check">
                                <Form.Check aria-label="option 1" {...register("nft_mgt_write", {
                                    onChange: (e) => {
                                        if (e.target.checked) setValue("nft_mgt_read", true)
                                    }
                                })} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Transaction Management</td>
                        <td>
                            <div className="user-management-check">
                                <Form.Check aria-label="option 1" {...register("transaction_mgt_read")} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}