import React from "react"
import { useNavigate } from "react-router-dom"

export const DashboardCard = (props) => {

    const navigate = useNavigate();

    const navigateToOtherPage = () => {
        if (props.name === "Users") navigate("/user_management?page=1")
        if (props.name === "NFTs") navigate("/nft_management?page=1")
        if (props.name === "Transactions") navigate("/transaction_management?page=1")
    }

    return (
        <div className="dashboad-content" role="button" onClick={() => navigateToOtherPage()}>
            <div className="dashboard-top">
                <div className="dashboard-left">
                    <h3>Total {props.name}</h3>
                    <span>
                        <div className="colored-text dashboard-area-top">{props.count}</div>
                    </span>
                </div>
                <div className="dashboard-right">
                    <img
                        src={props.img}
                        alt="user"
                    />
                </div>
            </div>
        </div>
    )
}