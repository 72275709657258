import React from "react"
import { RemoveCopyText, ShowCopyText } from "../../common/CopyText"
import { ShowFormatedDate } from "../../common/FormatDate";

export const UserData = (props) => {

    return (
        <div className="user-detail">
            <div className="detail-area">
                <div className="user-info">
                    <h4 className="mb-2"> Detail:</h4>
                    <p>
                        Name: <span>{props.firstname} {props.lastname}</span>
                    </p>
                    <p>
                        Email: <span>{props.email}</span>
                    </p>
                    <p>
                        Wallet Address: <span>{props.ethAddress ? <>{props.ethAddress} <i className="fa fa-clone" role="button" aria-hidden="true" id="copy-text" onClick={() => ShowCopyText(props.ethAddress)} onMouseOut={() => RemoveCopyText()}></i></> : "N/A"}</span>
                    </p>
                    <p>
                        Creation Date: <span>{props.createdAt ? ShowFormatedDate(props.createdAt) : ""}</span>
                    </p>
                </div>
                <div className="user-profile">
                    <img src={require("../../assets/images/user-profile.png")} alt="" />
                </div>
            </div>
        </div >
    )
}