import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DateRangeFilter } from "../../common/DateRangeFilter";
import { GlobalContext } from "../../context/GlobalContext";
import { DashboardApi } from "../../services/Services";
import { DashboardCard } from "./Card";
import { RecentTrans } from "./RecentTransactions";

export const Home = () => {

  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState({});
  const [dateFilter, setDateFilter] = useState({ start: "", end: "" });

  useEffect(() => {
    getDahboardData()
  }, [dateFilter])

  const getDahboardData = () => {
    DashboardApi(dateFilter.start, dateFilter.end).then(res => {
      setData(res.data.data)
    }).catch(err => {
      if (err.response.status === 401) {
        globalContext.setSessionComplete(true)
      }
      console.log('dashboard error ', err);
    })
  }

  return (
    <>
      <div className="dashboard-area">
        <Container fluid>
          <div className="d-flex dashboard-top-filter-area mb-3">
            {/* Date filter component */}
            <DateRangeFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
          </div>
          <Row>
            <Col md={4}>
              {/* Dashboard card component */}
              <DashboardCard name="Users" count={data.total_user} img={require("../../assets/images/dashboard-user.png")} />
            </Col>
            <Col md={4}>
              <DashboardCard name="NFTs" count={data.total_nfts} img={require("../../assets/images/dashboard-nft.png")} />
            </Col>
            <Col md={4}>
              <DashboardCard name="Transactions" count={data.total_transactions} img={require("../../assets/images/dashboard-transaction.png")} />
            </Col>
          </Row>
          {/* Recent Transactions component for recent 5 transactions */}
          <RecentTrans />
        </Container>
      </div>
    </>
  );
};
